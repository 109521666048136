export const darkOrange = '#FFC095'
export const mediumOrange = '#FCD7BE'
export const lightOrange = '#FFEAD5'

export const darkYellow = '#FBF09E'
export const mediumYellow = '#FFF8C5'
export const lightYellow = '#FFFAD7'

export const darkGreen = '#9FDAA9'
export const mediumGreen = '#BBECC3'
export const lightGreen = '#DFF5E3'

export const darkBlue = '#99E7F2'
export const mediumBlue = '#BFECF2'
export const lightBlue = '#DCF5F8'

export const darkPurple = '#B7B6FD'
export const mediumPurple = '#CDCCFB'
export const lightPurple = '#E6E5FF'
export const linkPurple = '#6B6AC9'

export const powderPink = '#FDB3E4'
export const mediumPink = '#FBC5E9'
export const lightPink = '#FCD9F1'

export const darkGrey = '#4B4E53'
export const mediumGrey = '#90949B'
export const grey = '#e0e0e0'
export const lightGrey = '#BFBFBF'
export const extraLightGrey = '#F7F7F7'

export const darkGrey75 = 'rgba(75, 78, 83, 0.75)'
export const darkGrey50 = 'rgba(75, 78, 83, 0.5)'
export const darkGrey35 = 'rgba(75, 78, 83, 0.35)'
export const darkGrey10 = 'rgba(75, 78, 83, 0.1)'

export const lightRed = '#F8F8F8'

export const mediumGrey25 = 'rgba(144, 148, 155, 0.25)'

export const white = '#FFFFFF'

export const white75 = 'rgba(255, 255, 255, 0.75)'
export const white50 = 'rgba(255, 255, 255, 0.5)'
export const white25 = 'rgba(255, 255, 255, 0.25)'
export const white10 = 'rgba(255, 255, 255, 0.1)'

export const lightPurple50 = 'rgba(230, 229, 255, 0.5)'
export const lightPurple30 = 'rgba(230, 229, 255, 0.30)'
export const lightGreen50 = 'rgba(223, 245, 227, 0.5)'

export const errorRed = '#DB3838'
export const highlightGreen = '#0DBB29'
export const facebookBlue = '#3B5998'
export const messengerBlue = '#0084FF'
export const twitterBlue = '#1DA1F2'

// NOTE: Colour scale compatible with the System UI specification
export const colors = {
  orange: [lightOrange, mediumOrange, darkOrange],
  yellow: [lightYellow, mediumYellow, darkYellow],
  green: [lightGreen, mediumGreen, darkGreen],
  blue: [lightBlue, mediumBlue, darkBlue],
  purple: [lightPurple, mediumPurple, darkPurple],
  pink: [lightPink, mediumPink, powderPink],
  grey: [extraLightGrey, lightGrey, mediumGrey, darkGrey],
  red: [lightRed],

  darkGrey75,
  darkGrey50,
  darkGrey35,
  darkGrey10,

  mediumGrey25,

  lightGreen50,

  white,

  linkPurple,
  lightPurple50,
  lightPurple30,

  white75,
  white50,
  white25,
  white10,

  errorRed,
  highlightGreen,

  facebookBlue,
  messengerBlue,
  twitterBlue,
}
