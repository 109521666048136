import { MouseEventHandler, useCallback } from 'react'
import css from '@styled-system/css'
import { layout, MinWidthProps, MaxWidthProps, variant, WidthProps } from 'styled-system'
import styled, { css as styledCss, StyledComponentProps } from 'styled-components'

import { gradient as gradientStyle, GradientType } from '@ui/theme/gradients'
import { Analytics } from 'analytics/Analytics'
import { Responsive, Theme } from '@ui/theme'

type CommonButtonProps = {
  eventClickTarget?: string
  selected?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLElement>
}

export type ConditionalButtonProps =
  | {
      variant?: Responsive<'normal' | 'flat' | 'transparent' | 'alert' | 'gradient'>
    }
  | {
      variant?: Responsive<'gradient'>
      gradient?: Responsive<GradientType>
    }

export type CombinedButtonProps = CommonButtonProps &
  ConditionalButtonProps &
  WidthProps &
  MaxWidthProps &
  MinWidthProps

type ButtonProps = StyledComponentProps<'button', Theme, CombinedButtonProps, ''>

const baseGradientStyles = {
  borderImage: gradientStyle.neutral,
  backgroundColor: 'white',
  borderWidth: '3px',
  borderStyle: 'solid',
  borderImageSlice: 1,
  boxShadow: 'button',
  willChange: 'box-shadow, transform',
  transition: 'box-shadow 0.1s linear, transform 0.1s linear',
  ':hover': {
    boxShadow: 'buttonHover',
    transform: 'translateY(-1px)',
  },
  ':active': {
    boxShadow: 'button',
    transform: 'translateY(0)',
  },
}

export const buttonVariants = variant({
  prop: 'variant',
  variants: {
    normal: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'grey.3',
      boxShadow: 'button',
      willChange: 'box-shadow, transform',
      transition: 'box-shadow 0.1s linear, transform 0.1s linear',
      ':hover': {
        transform: 'translateY(-1px)',
        boxShadow: 'buttonHover',
      },
      ':active': {
        boxShadow: 'button',
        transform: 'translateY(0)',
      },
    },
    flat: {
      backgroundColor: 'transparent',
      willChange: 'background-color',
      transition: 'background-color 0.2s linear',
      padding: 4,
      ':hover': {
        backgroundColor: 'darkGrey35',
      },
      ':active': {
        backgroundColor: '  darkGrey35',
      },
    },
    alert: {
      backgroundColor: 'transparent',
      willChange: 'background-color',
      transition: 'background-color 0.2s linear',
      padding: 4,
      ':hover': {
        backgroundColor: 'darkGrey35',
      },
      ':active': {
        backgroundColor: '  darkGrey35',
      },
      color: 'errorRed',
    },
    gradient: baseGradientStyles,
    transparent: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'grey.3',
      backgroundColor: 'transparent',
      willChange: 'background-color',
      transition: 'background-color 0.2s linear',
      padding: 4,
      ':hover': {
        backgroundColor: 'darkGrey35',
      },
      ':active': {
        backgroundColor: '  darkGrey35',
      },
    },
  },
})

const Button = ({
  onClick,
  eventClickTarget,
  variant = 'normal',
  selected = false,
  disabled = false,
  ...rest
}: ButtonProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (eventClickTarget) Analytics.trackButtonClick(eventClickTarget)
      if (onClick) onClick(e)
    },
    [eventClickTarget, onClick]
  )

  return (
    <ButtonElement
      onClick={(e) => handleClick(e)}
      variant={variant}
      selected={selected}
      disabled={disabled}
      data-event-click-target={eventClickTarget}
      {...rest}
    />
  )
}

const BUTTON_HEIGHT = 52

const getDisabledStyles = variant({
  prop: 'variant',
  variants: {
    normal: {
      borderColor: 'grey.2',
      color: 'grey.2',
      boxShadow: 'none',
      ':hover': {
        backgroundColor: 'white',
        boxShadow: 'none',
        transform: 'none',
      },
    },
    flat: {
      color: 'grey.2',
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
    transparent: {
      color: 'grey.2',
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
    alert: {
      color: 'grey.2',
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
    gradient: {
      color: 'grey.2',
      opacity: 0.7,
      ':hover': {
        boxShadow: 'button',
        transform: 'none',
      },
    },
  },
})

const getSelectedStyles = variant({
  prop: 'variant',
  variants: {
    normal: {
      backgroundColor: 'grey.3',
      boxShadow: 'none',
      color: (props: CombinedButtonProps) => (props.disabled ? 'grey.2' : 'white'),
      ':hover': {
        backgroundColor: 'grey.3',
        boxShadow: 'none',
      },
    },
    flat: {
      backgroundColor: 'mediumGrey25',
      ':hover': {
        backgroundColor: 'mediumGrey25',
      },
    },
    transparent: {
      backgroundColor: 'mediumGrey25',
      ':hover': {
        backgroundColor: 'mediumGrey25',
      },
    },
  },
})

const getGradientStyles = variant({
  prop: 'gradient',
  variants: {
    nanny: { ...baseGradientStyles, borderImage: gradientStyle.nanny },
    family: { ...baseGradientStyles, borderImage: gradientStyle.family },
    share: { ...baseGradientStyles, borderImage: gradientStyle.share },
    familypair: { ...baseGradientStyles, borderImage: gradientStyle.familypair },
    neutral: { ...baseGradientStyles, borderImage: gradientStyle.neutral },
  },
})

export const commonButtonStyles = styledCss<CombinedButtonProps>`
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  letter-spacing: 2px;
  line-height: 21px;
  text-transform: uppercase;
  min-height: ${BUTTON_HEIGHT}px;
  max-height: ${BUTTON_HEIGHT}px;
  text-align: center;
  outline: 0;
  user-select: none;
  text-decoration: none;
  border-radius: 0;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    color: 'grey.3',
    fontSize: 0,
    fontWeight: 'bold',
    px: 4,
    py: 0,
    minWidth: 'auto',
    ':active': {
      textDecoration: 'none',
    },
  })}

  ${buttonVariants}
  ${getGradientStyles}
  ${(props) => props.disabled && getDisabledStyles}
  ${(props) => props.selected && getSelectedStyles(props)}

  ${(layout as any).width}
  ${(layout as any).maxWidth}
  ${(layout as any).minWidth}
`

const ButtonElement = styled.button`
  ${commonButtonStyles}

  /* Safari does not allow buttons to be flex items */
  display: inline-block;
`

export default Button
