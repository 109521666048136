export const zIndex = {
  paper: 100,
  tooltip: 200,
  stickyCtas: 300,
  navigation: 500,
  overlay: 1000,
  modal: 3000,
  datePicker: 4000,
  snackbar: 5000,
  loading: 6000,
  congratulationsPage: {
    confettiContainer: 2,
    cta: 1,
    confettiIcon: 10,
  },
}
