import * as Sentry from '@sentry/nextjs'
import { ApolloClient, ApolloError } from '@apollo/client'
import { GetServerSidePropsContext } from 'next'

import { homePath } from 'routes/static'
import { getLocaleFromServerRequest } from 'locale/utils'
import { AppPageProps } from '_app.page'
import { FieldError, Maybe } from '@graph/types/global.generated'

import { PROMO_KEY, VALID_PROMO_CHARACTERS } from './constants'

export const cleanWhitespace = (text: string) => {
  return text
    .replace(/\n/g, '')
    .replace(/ +(?= )/g, '')
    .trim()
}

export const absoluteURL = (url = '') => {
  const host = process.env.NEXT_PUBLIC_NL_WEB_CLIENT_DOMAIN
  return url.startsWith('/') ? `https://${host}${url === '/' ? '' : url}` : url
}

export const isDevEnvironment = process.env.NEXT_PUBLIC_APP_ENV === 'development'

export const isStagingEnvironment =
  process.env.NEXT_PUBLIC_APP_ENV === 'staging' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'staging2' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'staging3' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'staging4'

export const getOrganizationStructuredData = () => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Nanny Lane',
    sameAs: absoluteURL(homePath),
    url: absoluteURL(homePath),
    logo: absoluteURL('/images/logo-gradient-512x512.png'),
    description: 'Nanny Lane connects nannies with the best nanny jobs in their area.',
  })
}

export const isRunningSpecs = () => {
  if (typeof window !== 'undefined') {
    return (
      !!(window as any).Cypress || navigator?.userAgent.toLowerCase().indexOf(' electron/') > -1
    )
  } else {
    return false
  }
}

/**
 * This must be included on every page
 *
 * __APOLLO_STATE__ contains the data returned from getServerSideProps.
 * This cached data is used to hydrate the Apollo client side cache.
 * This replaces the need to inject getServerSideProps data into the <script>
 *
 * @param client Apollo client instance
 * @param context Next context
 * @param pageProps
 * @returns pageProps decorated with localeFromServerCookie and __APOLLO_STATE__
 */
export const addGlobalPageProps = <P>(
  client: ApolloClient<any>,
  context: GetServerSidePropsContext,
  pageProps: P & { includeCountrySelectPanel?: boolean }
): { props: P & AppPageProps } => {
  return {
    props: {
      includeCountrySelectPanel: true,
      ...pageProps,
      localeFromServerCookie: getLocaleFromServerRequest(context) || null,
      autoFillPromoCode: deriveAutoFillPromo(context) || null,
      __APOLLO_STATE__: client.cache.extract(),
    },
  }
}

// Derives the promo code to be auto-filled on the checkout page. The value will be from the query parameter or from the promo cookie
const deriveAutoFillPromo = (context: GetServerSidePropsContext) => {
  const promoFromQuery = context.query[PROMO_KEY]
  const existingPromoFromCookie = context.req.cookies[PROMO_KEY]

  if (promoFromQuery) {
    // This accepts characters and numbers to up 100 characters long. this does not validate the code, it only ensures garbage isn't added to the cookie
    if (typeof promoFromQuery === 'string' && VALID_PROMO_CHARACTERS.test(promoFromQuery)) {
      if (existingPromoFromCookie !== promoFromQuery) {
        // Store the promo code in a cookie for future use
        context.res.setHeader('Set-Cookie', `promo=${promoFromQuery}; Path=/; Max-Age=604800`) // one week expiry
      }
      return promoFromQuery
    }
  }

  return existingPromoFromCookie
}

export const pluralize = (count: number, singular: string, plural?: string) => {
  return count === 1 ? singular : plural || `${singular}s`
}

export const trimAfterSentence = (value: string, length: number = 300) => {
  if (value.length <= length) return value

  const lastSentenceEndingIndex = value.lastIndexOf('.', length)

  if (lastSentenceEndingIndex === -1) return value

  return value.substring(0, lastSentenceEndingIndex + 1)
}

export const convertCentsToDollars = (valueInCents?: number) =>
  valueInCents ? parseFloat((valueInCents / 100).toFixed(2)) : 0

export const parseDateString = (dateString: string): Date | undefined => {
  const [year, month, day] = dateString.split('-').map(Number)

  const date = new Date(year, month - 1, day, 0, 0, 0)
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return undefined
  }

  return date
}

export const filterNullConnectionNodes = <T>(edges: Maybe<{ node: Maybe<T> }>[]) => {
  return edges.map((edge) => edge?.node).filter((node) => !!node) as NonNullable<T>[]
}

/**
 * Ensures Sentry is passed an Error instance
 */
export const captureSentryException = (
  error: Error | ApolloError | string | FieldError | unknown
): Error => {
  if (error instanceof Error) {
    Sentry.captureException(error)
    return error
  }

  if (typeof error === 'string') {
    const newError = new Error(error)
    Sentry.captureException(newError)
    return newError
  }

  const fieldError = error as FieldError

  if (!!fieldError?.error && !!fieldError?.name) {
    const newError = new Error(fieldError.error)
    newError.name = fieldError.name
    Sentry.captureException(newError)
    return newError
  }

  const unknownError = new Error('Unknown Error')
  Object.assign(unknownError, error)
  Sentry.captureException(unknownError)
  return unknownError
}
